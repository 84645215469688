var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.studentCredential.user.gender)?_c('div',{staticClass:"avatar-page"},[_c('div',{staticClass:"return"},[(_vm.studentCredential.isTeacher)?_c('span',{staticClass:"icon",on:{"click":() => {
            this.$router.push({ name: 'student-step-1' });
          }}},[_c('svg',{staticStyle:{"fill":"black"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 36 36"}},[_c('path',{attrs:{"d":"M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.003 1.003 0 1 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z","stroke-width":"1.6","fill-rule":"evenodd","stroke-linecap":"round","stroke-linejoin":"round"}})])]):_vm._e(),(!_vm.studentCredential.isTeacher)?_c('span',{staticClass:"icon",on:{"click":() => {
            this.$router.push({ name: 'student-step-1' });
          }}},[_c('svg',{staticStyle:{"fill":"black"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 36 36"}},[_c('path',{attrs:{"d":"M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.003 1.003 0 1 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z","stroke-width":"1.6","fill-rule":"evenodd","stroke-linecap":"round","stroke-linejoin":"round"}})])]):_vm._e()]),(_vm.currentTypeId)?_c('div',[(_vm.studentCredential.main_product.brand.name.includes('Mooney'))?_c('Avatar',{attrs:{"character_slug":_vm.studentCredential.main_product.sku,"character_id":_vm.studentCredential.main_product.character_id,"product_id":_vm.studentCredential.main_product.builder_id,"name":_vm.studentCredential.user.custom_name,"uuid":_vm.studentCredential.uuid,"is_male":_vm.studentCredential.user.gender === 'M' ? true : false,"builderLayoutId":_vm.studentCredential.main_product.custom_properties.layouts[0].id,"type_id":_vm.currentTypeId,"gradient_colors":{
          bgInner: '#FFFFFF',
          bgCentral: '#B7FEFF',
          bgOuter: '#288AFF',
        },"custom_dedicatoria":_vm.studentCredential.dedication,"is_subscription":true,"language":'pt',"custom_mooner":true},on:{"completed":_vm.avatarMethods}}):_c('Avatar',{attrs:{"character_slug":_vm.studentCredential.main_product.sku,"character_id":_vm.studentCredential.main_product.character_id,"product_id":_vm.studentCredential.main_product.builder_id,"type_id":_vm.currentTypeId,"name":_vm.studentCredential.user.custom_name,"uuid":_vm.studentCredential.uuid,"is_male":_vm.studentCredential.user.gender === 'M' ? true : false,"gradient_colors":{
          bgInner: '#FFFFFF',
          bgCentral: '#B7FEFF',
          bgOuter: '#288AFF',
        },"builderLayoutId":_vm.studentCredential.main_product.custom_properties.layouts &&
          _vm.studentCredential.main_product.custom_properties.layouts[0] &&
          _vm.studentCredential.main_product.custom_properties.layouts[0].id
            ? _vm.studentCredential.main_product.custom_properties.layouts[0].id
            : null,"custom_dedicatoria":_vm.studentCredential.dedication ? _vm.studentCredential.dedication : ' ',"custom_numero":_vm.custom_numero,"custom_mes":_vm.custom_mes,"is_subscription":true,"language":'pt',"custom_mooner":false},on:{"completed":_vm.avatarMethods}})],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }