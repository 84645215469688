<template>
  <div class="avatar-page" v-if="studentCredential.user.gender">
    <div class="return">
      <span
        class="icon"
        @click="
          () => {
            this.$router.push({ name: 'student-step-1' });
          }
        "
        v-if="studentCredential.isTeacher"
      >
        <svg
          style="fill: black"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 36 36"
        >
          <path
            d="M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.003 1.003 0 1 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z"
            stroke-width="1.6"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </span>
      <span
        class="icon"
        @click="
          () => {
            this.$router.push({ name: 'student-step-1' });
          }
        "
        v-if="!studentCredential.isTeacher"
      >
        <svg
          style="fill: black"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 36 36"
        >
          <path
            d="M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.003 1.003 0 1 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z"
            stroke-width="1.6"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </span>
    </div>

    <div v-if="currentTypeId">
      <Avatar
        :character_slug="studentCredential.main_product.sku"
        :character_id="studentCredential.main_product.character_id"
        :product_id="studentCredential.main_product.builder_id"
        :name="studentCredential.user.custom_name"
        :uuid="studentCredential.uuid"
        :is_male="studentCredential.user.gender === 'M' ? true : false"
        :builderLayoutId="
          studentCredential.main_product.custom_properties.layouts[0].id
        "
        :type_id="currentTypeId"
        :gradient_colors="{
          bgInner: '#FFFFFF',
          bgCentral: '#B7FEFF',
          bgOuter: '#288AFF',
        }"
        :custom_dedicatoria="studentCredential.dedication"
        :is_subscription="true"
        :language="'pt'"
        @completed="avatarMethods"
        :custom_mooner="true"
        v-if="studentCredential.main_product.brand.name.includes('Mooney')"
      />

      <Avatar
        :character_slug="studentCredential.main_product.sku"
        :character_id="studentCredential.main_product.character_id"
        :product_id="studentCredential.main_product.builder_id"
        :type_id="currentTypeId"
        :name="studentCredential.user.custom_name"
        :uuid="studentCredential.uuid"
        :is_male="studentCredential.user.gender === 'M' ? true : false"
        :gradient_colors="{
          bgInner: '#FFFFFF',
          bgCentral: '#B7FEFF',
          bgOuter: '#288AFF',
        }"
        :builderLayoutId="
          studentCredential.main_product.custom_properties.layouts &&
          studentCredential.main_product.custom_properties.layouts[0] &&
          studentCredential.main_product.custom_properties.layouts[0].id
            ? studentCredential.main_product.custom_properties.layouts[0].id
            : null
        "
        :custom_dedicatoria="
          studentCredential.dedication ? studentCredential.dedication : ' '
        "
        :custom_numero="custom_numero"
        :custom_mes="custom_mes"
        :is_subscription="true"
        :language="'pt'"
        @completed="avatarMethods"
        :custom_mooner="false"
        v-else
      />
    </div>

    <!-- <CustomizationView
			v-if="studentCredential.subscription !== null"
			:subscription="studentCredential.subscription"
			:builder-api="'https://builder.dentrodahistoria.com.br/api'"
			:load-avatar="studentCredential.subscription.loadAvatar"
			@avatarMethods="avatarMethods"
		/> -->

    <!-- <vuestic-modal
			:show.sync="show"
			ref="modal"
			v-bind:medium="true"
			v-bind:large="true"
			:okText="'modal.confirm' | translate"
			:cancelText="'modal.cancel' | translate"
			:noButtons="true"
			:cancelDisabled="true"
		>
			<div class="va-row">
				<div class="flex md8 xs12 modal-text no-padding">
					<h1 slot="title"></h1>
					<h1>Editar Avatar</h1>

					O avatar da criança
					<b>{{ studentCredential.user.custom_name }}</b> foi alterado.

					<br /><br />
					A atualização é somente para esse ou<br />
					para todos os os livros da criança?
				</div>

				<div class="flex md4 xs12 modal-avatar">
					<img
						v-for="customization in customizations"
						:key="customization.categoryId"
						:src="customization.optionAsset"
						:style="[
							{
								zIndex:
									customization.optionZIndex != null
										? customization.optionZIndex
										: customization.categoryZIndex,
							},
						]"
					/>
				</div>
				<div class="clearfix"></div>
			</div>

			<hr />

			<div class="va-row text-right action-area">
				<div class="flex md12 text-right no-padding">
					<button class="btn cancel" @click="saveUser(uuid, true)">
						ATUALIZAR TODOS
					</button>
					<button class="btn btn-right" @click="saveUser(uuid, false)">
						ATUALIZAR ESSE
					</button>
				</div>
			</div>
		</vuestic-modal> -->
  </div>
</template>

<script>
const axios = require("axios");

import { mapGetters } from "vuex";
// import CustomizationView from "src/components/kits/avatar/CustomizationView.vue";

export default {
  name: "AvatarTeacher",
  components: {
    // CustomizationView,
  },
  computed: {
    ...mapGetters({
      studentCredential: "studentCredential",
      teacherCredential: "teacherCredential",
      // ...
    }),
  },

  data() {
    return {
      show: true,
      customizations: {},
      uuid: null,
      characterName: "",
      characterIsMale: "",
      characterDedication: "",
      currentTypeId: null,

      custom_numero: null,
      custom_mes: null,
      //subscription: null,
    };
  },

  mounted() {
    const self = this;
    //this.subscription = userCredential.subscription
    //document.getElementsByClassName("ddh-sidebar")[0].style.display = 'none'

    this.uuid = this.studentCredential.uuid;

    if (self.studentCredential.main_product) {
      self.currentTypeId =
        self.studentCredential.main_product.types[
          self.studentCredential.user.gender
        ];
    } else if (self.studentCredential.main_product.types) {
      self.currentTypeId =
        self.studentCredential.main_product.types[
          self.studentCredential.user.gender
        ];
    }

    if (self.studentCredential.main_product.custom_numero) {
      self.custom_numero = parseInt(
        self.studentCredential.main_product.custom_numero
      );
    }

    if (self.studentCredential.main_product.custom_mes) {
      self.custom_mes = parseInt(
        self.studentCredential.main_product.custom_mes
      );
    }
  },

  methods: {
    // cancel () {
    //   this.$emit('cancel')
    //   //alert("aff");
    //   this.show = false
    //   this.show.sync = false
    //   this.close()
    //   window.removeEventListener('keyup', this.listenKeyUp)
    // },
    // close () {
    //   this.show = false
    //   window.removeEventListener('keyup', this.listenKeyUp)
    // },

    avatarMethods(payload) {
      const self = this;

      this.customizations = payload.updatedCustomizations;
      this.uuid = payload.updatedUuid;
      this.characterName = payload.characterName;
      this.characterIsMale = payload.characterIsMale;
      this.characterDedication = payload.characterDedication;

      if (self.studentCredential.user.products.length == 0) {
        // alert(
        // 	"Primeiro fluxo de criar livros, deu bom! Vai pro preview e gerei portabilidade"
        // );
        self.saveUser(this.uuid, true);
      } else if (self.studentCredential.user.products.length == 1) {
        // alert(
        // 	"Esse fluxo só tem um livro esperado em mooney ou de um livro só."
        // );
        self.saveUser(this.uuid, true);
      } else if (self.studentCredential.user.products.length > 1) {
        // alert(
        // 	"Ele tem dois livros, pode dar merda com livros que não tem portabilidade (Caso do editar Livro 1)"
        // );

        self.$router.push({
          name: "preview",
          params: {
            //product: product,
            isTeacher: true,
            grade: self.grade,
            // triggerTrade: false,
            isPriting: false,
          },
        });

        self.$store.commit(
          "UPDATE_STUDENT_UUID_CUSTOMIZATION",
          this.customizations
        );
        self.$store.commit("UPDATE_STUDENT_UUID", this.uuid);
      } else {
        //alert("Caso foderoso que tem 2 livros e tem portabilidade");
      }
    },

    saveUser(uuid, triggerTrade) {
      const self = this;

      let config = {
        headers: {
          Authorization: "Bearer " + this.studentCredential.token,
        },
      };

      let product = {
        builderCharacterId: uuid,
        builderProductId: self.studentCredential.main_product.builderProductId,
        isStudent: true,
      };

      //self.$router.push({ name: 'preview', params: { product: product } })
      // alert("okok");

      self.$router.push({
        name: "preview",
        params: {
          product: product,
          isTeacher: self.isTeacher,
          isPriting: false,
          haveDedication: self.isTeacher
            ? self.teacherCredential.settings.change_dedication
            : false,
          grade: self.grade,
          triggerTrade: triggerTrade,
        },
      });

      self.$store.commit("UPDATE_STUDENT_UUID", uuid);

      // var finalData = axios
      // 	.post(
      // 		process.env.VUE_APP_ROOT_API + "/students/save",
      // 		{
      // 			uuid: uuid,
      // 			old_uuid: self.studentCredential.old_uuid,
      // 			gradeId: self.studentCredential.gradeId,
      // 			productId: self.studentCredential.main_product.id,
      // 			triggerTrade: triggerTrade,
      // 			isUpdate: self.studentCredential.isUpdate,
      // 			name: self.studentCredential.user.custom_name,
      // 			type_id:
      // 				self.studentCredential.main_product.types[
      // 					self.studentCredential.user.gender
      // 				],
      // 		},
      // 		config
      // 	)
      // 	.then(function (response) {
      // 		self.$router.push({
      // 			name: "preview",
      // 			params: {
      // 				product: product,
      // 				isTeacher: self.isTeacher,
      // 				isPriting: false,
      // 				haveDedication: self.isTeacher
      // 					? self.teacherCredential.settings.change_dedication
      // 					: false,
      // 				grade: self.grade,
      // 				triggerTrade: triggerTrade,
      // 			},
      // 		});

      // 		self.$store.commit("UPDATE_STUDENT_UUID", uuid);

      // 		return response;
      // 	})
      // 	.catch(function (error) {
      // 		//alert(error.message);
      // 		alert("Não é possível alterar a customização para este usuário.");
      // 		//console.log(error);
      // 		//this.errors.push(error);
      // 	});

      // return finalData;
    },

    updateAll() {
      axios({
        method: "PUT",
        url:
          process.env.VUE_APP_ROOT_API +
          "/subscriptions/" +
          this.studentCredential.subscription.subscription.id +
          "/stack/character/all",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          CharacterName: this.characterName,
          BuilderCharacterId: this.uuid,
        },
      }).then((result) => {
        this.$router.push({ name: "next-kits" });
      });
    },

    updateThis() {
      axios({
        method: "PUT",
        url:
          process.env.VUE_APP_ROOT_API +
          "/subscriptions/" +
          this.studentCredential.subscription.subscription.id +
          "/stack/" +
          this.studentCredential.subscription.subscription.subscriptionStackId +
          "/character/",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          CharacterName: this.characterName,
          BuilderCharacterId: this.uuid,
        },
      }).then((result) => {
        this.$router.push({ name: "next-kits" });
      });
    },
  },

  beforeDestroy() {
    //document.getElementsByClassName("ddh-sidebar")[0].style.display = 'block'
  },
};
</script>

<style scoped lang="scss">
hr {
  margin: 10px -1.5rem;
}
h1 {
  font-family: "Nunito";
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 15px;

  &:first-child {
    display: none;
  }
}

.ddh-sidebar,
.ddh-sidebar.hide-sidebar {
  display: none !important;
}

html {
  --scale: 1;
  --translate: 0%;
}

.loading-view {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.avatar-page {
  min-height: 100vh;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: white;
  @include media-breakpoint-down("sm") {
    min-height: 0;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }
}
.return {
  position: relative;
  z-index: 99;
  .icon {
    width: 38px;
    height: 38px;
    position: absolute;
    z-index: 30;
    padding: 0.95238rem 2.77778vw;
    svg {
      width: 38px;
      height: 38px;
      path {
        fill: #afa2ba !important;
        stroke: #afa2ba !important;
        transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
    &:hover {
      cursor: pointer;
      svg,
      path {
        fill: #1ad3d9 !important;
        stroke: #1ad3d9 !important;
      }
    }
    &:active {
      svg {
        transform: scale(0.9);
      }
    }
  }
}
::v-deep .vuestic-modal {
  .modal-dialog {
    width: 96% !important;
    max-width: 600px;
    border-radius: 10px !important;
    overflow: hidden;

    @include media-breakpoint-down("sm") {
      width: unset;
    }
    .modal-content {
      width: 100%;
      // margin-top: 150px;
      border-radius: 10px !important;

      .modal-header {
        height: 0;
        padding: 0px;
        border-bottom: none;
        font-size: 1.2rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: absolute;
        width: 90%;

        .title {
          display: none;
        }
      }

      .modal-body {
        padding: 0;
        padding-bottom: 0px;

        .va-row {
          padding: 0;
          font-family: "Roboto";
          font-size: 16px;
          color: black;
          display: flex;

          .modal-text {
            //flex: 0 0 65%;
            // margin-right: 25px;
            // @include media-breakpoint-down('sm') {
            //   flex: 0 0 50%;
            // }

            // @media(max-width: 990px){
            //   flex: 0 0 100%;
            // }

            b {
              text-transform: uppercase;
            }
          }
        }
      }

      .modal-footer {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 0 0px;
        // padding-bottom: 15px;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-bottom: 0px !important;
        padding-top: 15px !important;
      }
    }
  }
}

.modal-actions {
  // padding: 25px 30px;
  border-top: 1px solid #dfdae4;
  display: flex;
  justify-content: flex-end;

  .btn {
    flex: 1;
    border: 0;
    &:hover {
      color: #34495e;
    }
  }
  .btn-todos {
    margin-right: 5px;
    background: white;
    border: 1px solid #dfdae4;
    color: #4965bc;
  }
  .btn-esse {
    margin-left: 5px;
  }
}

::v-deep .modal-footer {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0px;
  padding-bottom: calc(25px - 20px);
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 15px;
}

::v-deep .modal-content {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 25px;
  padding-bottom: 0px;
}

::v-deep .modal-body {
  padding: 0px !important;
  padding-bottom: 0px;
}

::v-deep .modal-header {
  height: 55px;
  padding: 0px;
  border-bottom: none;
  font-size: 1.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.close-modal {
  margin-left: 1rem;
  font-size: $font-size-large;
  line-height: $font-size-large;
  cursor: pointer;

  display: none;

  @media (max-width: 990px) {
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 9999;
  }
}

// ::v-deep .action-area {
// 	justify-content: flex-end;
// 	padding: 0 0px;
// 	flex-wrap: wrap;
// 	padding-bottom: 0px !important;
// 	padding-top: 15px !important;
// }

.action-area {
  padding-top: 0px;
  padding-bottom: 12px !important;

  .btn-right {
    margin-left: 8px;
  }
}
</style>
